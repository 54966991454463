<template>
    <div>
        <el-form ref="queryForm" class="query-box"  v-model="queryParams" :inline="true">
            <el-form-item label="业务类型" prop="type" label-width="120px">
                <el-select v-model="queryParams.type"
                           placeholder="业务类型" clearable size="small" style="width: 240px">
                    <el-option label="全部" value=""/>
                    <el-option v-for="(value, key) in dicts.BLACK_WHITE_TYPE" :label="value" :value="key" :key="key"/>
                </el-select>
            </el-form-item>
            <el-form-item label="角色类型" prop="type" label-width="120px">
                <el-select v-model="queryParams.userRole"
                           placeholder="角色类型" clearable size="small" style="width: 240px">
                    <el-option label="全部" value=""/>
                    <el-option label="机构" value="ORG"/>
                    <el-option label="代理商" value="AGENT"/>
                    <el-option label="商户" value="MER"/>
                </el-select>
            </el-form-item>
            <el-form-item label="配置对象编号" prop="name" label-width="120px">
                <el-input v-model="queryParams.agentNo"
                          clearable
                          placeholder="请输入配置对象编号"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'BLACK_WHITE_CONFIG_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery" v-permission="'BLACK_WHITE_CONFIG_QUERY'">
                    重置
                </el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini"
                           @click="handleAdd"
                           v-permission="'BLACK_WHITE_CONFIG_INSERT'">
                    配置
                </el-button>
            </el-col>
        </el-row>

        <CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true"></CustomForm>
    </div>
</template>

<script>
    import CustomForm from "./CustomForm";
    import {SpecialApi} from '@/api';
    export default {
        name: "ToolBar",
        components: {CustomForm},
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
            }
        },
        mounted() {
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出黑白名单配置",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await SpecialApi.blackWhiteConfig.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                let [startCreateTime, endCreateTime] = this.createDateRange;
                this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
                this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
